.lets_get_started_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.input_field_container{
    padding-top: 30px;
    width: 100%;
    
}
.form_card{
    height: 100vh;
}
.form_heading{
    height: 45px;
    color: #000;
    font-family: Mulish;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.form_paragraph{
    height: 20px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height:20px;
}
.input_fields{
    color: #000;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    width: 47%;
    margin-bottom: 20px;
}
.form_label{
    margin-bottom: 12px;
    font-size: 14px;
}
.form_input_box{
    width: 100%;
    padding: 14px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    color: #777E90;
    border:1px solid #DFDFDF;
}
.form_select_box{
    width: 100%;
    padding: 6px 5px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    color: #777E90;
    border:1px solid #DFDFDF;
}
.lets_get_you_started_button{
    width: 162px;
    height: 42px;
    background-color: #00A656;
    color: #FFF;
    text-align: center;
    font-family: Mulish;
    font-size: 17px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
}
.HQ_country{
    color: #777E90;
    font-size: 12px;
}
.lgs-modal{
    width: 40% !important;
    height: 85% !important;
    background: white;
    border-radius: 10px;
    padding: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.required-asterisk {
    color: red;
  }
  .error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 0.5em;
  } 

@media screen and (max-width: 767px) {
    .lgs-modal {
        min-width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        border-radius: 0;
        padding: 20px;
        position: fixed;
        overflow: auto;
    }
    
    .input_fields {
        width: 100%;
    }
    .form_paragraph{
        color: #777E90;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
    }
    .lets_get_you_started_button{
        width: 100% !important;
        margin-bottom: 20px;
    }
    .form_heading{
        font-size: 20px;
        height: 30px;
    }
    .form_paragraph{
        font-size: 16px;
    }
    .lgs_button_container{
        width: 100%;
    }
}

 @media screen and (min-width: 768px) and (max-width: 819px) {
    .lgs-modal{
      width: 75% !important;
      height: 60% !important;
    }
  }

  @media screen and (min-width: 820px) and (max-width: 1023px) {
    .lgs-modal{
        width: 80% !important;
        height: 60% !important;
      }
  }

  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .lgs-modal{
        width: 78% !important;
        height: 80% !important;
      }
  } 

  @media (min-width: 992px){
    .lgs-modal{
        max-width: 600px !important;
        height:600px !important
    }
   
  }

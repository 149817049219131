.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.welcome-custom-modal {
  width: 47% !important;
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.welcome_note_img {
  width: 80%;
}

.welcome_heading {
  color: #0b132a;
  font-family: Mulish;
  font-size: 42px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.welcome_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.welcome_paragraph {
  color: #4f5665;
  text-align: center;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
  width: 80%;
}

.start_button {
  width: 25% !important;
  height: 48px;
  color: #fff;
  font-family: Mulish;
  font-size: 20px;
  font-weight: 700;
  background-color: #00a656;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .welcome-custom-modal {
    min-width: 100% !important;
    height: 100% !important;
    max-width: none;
    max-height: none;
    border-radius: 0;
    background-image: url("../../assets/ESGPilot_background.svg");
    background-size: cover;
    background-position: center;
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px !important;
  }
  .start_button {
    width: 90% !important;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

  }
  .welcome_paragraph{
    font-size: 16px;
    width: 70%;
  }
  .home-container{
    overflow: hidden;
  }
  .welcome_note_img {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 819px)   {
  .welcome-custom-modal{
    width: 76% !important;
  
  }
} 

@media screen and (min-width: 820px) and (max-width: 1023px)   {
  .welcome-custom-modal{
    width: 76% !important;
  
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px)  {
  .welcome-custom-modal{
    width:80% !important;
   
  }
 
} 
@media (min-width: 992px){
  .welcome-custom-modal{
      max-width: 600px !important;
  }
  .welcome_paragraph{
    width:100%;
  }
}

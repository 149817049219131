.iterations-container{
    margin-left: 88px;
}

.empty-iterations-message{
    font-family: Mulish;
    color: #0B132A;
    font-size: 32px;
    font-weight: 500;

}

.table_heading_style{
    font-size: 16px;
    font-family: Mulish;
    text-align: center;
}

.table_body_style{
    font-size: 13px;
    font-family: Mulish;
    text-align: center;
}
.table_width{
    width:250px;
    text-align: center;
}
.table_width_action{
    width:200px;
}
.table_width_status{
    width:150px;
}

.files_image{
    width: 250px;
    height: 250px;
}
.upload_doc_text{
    color: #4F5665;
    text-align: center;
    font-family: Mulish;
    font-size: 18px;
    font-weight: 400;
}
.get_started_btn{
    color: #FFF;
    text-align: center;
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    background-color:#00A656;
    border: none;
    padding: 12px ;
    border-radius: 5px;
}

@media screen and (max-width:767px) {
    .iterations-container{
        margin-left: 0;
    }
    
}
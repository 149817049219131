.loading-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999; /* Adjust the z-index value as needed */
  }
  
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loading-spinner img {
    width: 200px; /* Adjust the size of the loading spinner image */
  }
  
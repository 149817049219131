.submission_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.close {
  font-size: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 0;
}
.submission_note_img {
  width: 20%;
}
.submission_heading {
  color: #00a656;
  font-family: Mulish;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.submission_paragraph {
  color: #4f5665;
  text-align: center;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  width: 85%;
  margin: 0;
  margin-bottom: 20px;
}
.close_button {
  width: 26%;
  height: 48px;
  flex-shrink: 0;
  color: #fff;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  background-color: #00a656;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.submission-model {
  width: 47% !important;
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0px;
}

@media screen and (max-width: 767px) {
  .submission-model {
    min-width: 100% !important;
    height: 100% !important;
    padding: 18px;
    margin: 0px !important;
    border-radius: 0;
    display: block;
    padding-top: 30%;
  }
  .close_button {
    width: 90% !important;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .submission_note_img {
    width: 70%;
  }
  .submission_heading {
    color: #0b132a;
    font-family: Mulish;
    font-size: 30px;
  }
  .submission_paragraph {
    font-size: 16px;
    width: 100%;
  }
  .submission_card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* @media screen and (min-width: 768px) and (max-width: 819px) {
  .submission-model{
      width:85%;
     
  }
}

@media screen and (min-width: 820px) and (max-width: 1023px) {
  .submission-model{
      width:80%;
     
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .submission-model{
      width:70%;
     
  }
}  */
@media (min-width: 768px) {
  .submission-model{
   width:81% !important;
  }
   }
 

@media (min-width: 992px) {
 .submission-model{
  width:62% !important;
 }
  }

  
@media (min-width: 1200px) {
  .submission-model{
   width:55% !important;
  }
   }



@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
body {
    font-family: "Open Sans", sans-serif;
    margin: 0;
}
a {
    text-decoration: none;
    color: #4F5665;
    /* font-family: Mulish; */
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

}
a:hover {
    color: rgb(179, 179, 179);
}

.report-heading1
{
    font-weight: 700;
    margin: 5px 0px 0px 0px;
    color: #23719F;
    font-size: 24px;
    font-family: "DM Sans";
}
.report-heading
{
    font-weight: 700;
    margin: 5px 0px 0px 0px;
    font-size: 24px;
    font-family: "DM Sans";
}
.overall_bg{
    background-color:#FFFFFF ;
    border-radius: 14px;
    padding-top: 20px;
}
.environment_bg{
    background-color:#FFF ;
    border-radius: 14px;
    padding-top: 20px;
}
.report-bg svg{
    background-color: #fff;
    border-radius: 5px;
}

.selectOptions
{
    text-align-last: left;
    padding-right: 29px;
    direction: rtl !important;
}

.frameworkOptions{
    background-color: #fff;
    border: none;
    padding: 14px;
    border-radius: 10px;
    width: 100%;
    outline: none;
}

.home-container{
   margin-left: 88px;
}

.edit-top{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}

.edit-page-button{
    height: 38px;
    padding: 6px 12px;
    border: none;
    background-color: #fff;
    border-radius: 10px;
    color: #00A656;
    font-weight: 700;
    font-family: Mulish;
}
.plus_icon{
    margin-right: 4px;
}
.upload-documents-button{
    height: 38px;
    padding: 6px 12px;
    border: none;
    background-color: #fff;
    border-radius: 10px;
    color: #fff !important;
    font-weight: 700;
    font-family: Mulish;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload-button{
    background-color: #fff;
    margin-left: 24px;
    /* color: #00A656 !important; */
}
.generate-button{
    background-color: #00A656;
    margin-left: 24px;
    color: #fff;
}
  select::-ms-expand {
    display: none;
  }

  select option {
    padding: 20px;
  }

.select-framework{
    padding: 16px 12px !important;
    color: #000 !important;
    background-color: #fff !important;
    outline: none !important;
    border-radius: 10px !important;
    font-family: DM Sans;
    border: none !important;
}

.select-framework:focus{
    border-color: transparent !important;
    box-shadow: none !important;
}

/* redesign reports  page*/
.reports_dropdown{
    padding: 16px 12px !important;
    color: #000000 !important;
    background-color: #FFFFFF !important;
    outline: none !important;
    border-radius: 10px !important;
    font-family: DM Sans;
    border: none !important;
    width: 100% !important;
    box-shadow: none !important;
}
.reports_edit_button{
    color: #00A656 !important;
    background-color:#FFFFFF !important;
    border: none !important;
    outline: none !important;
    width: 5% !important;
    min-width: 62px;
    font-weight: 700 !important;
    float: right;
    font-family: Mulish !important;
    border-radius: 10px !important;
    margin-right: 10px;
}
.reports_export_button{
    color: #FFFFFF !important;
    background-color:#00A656 !important;
    border: none !important;
    outline: none !important;
    float: right;
    min-width: 85px;
    font-weight: 700 !important;
    font-family: Mulish !important;
    width: 50% !important;
    border-radius: 10px !important;
}
.pdf_export_button{
    color: #FFF !important;
    text-align: center !important;
    font-family: Mulish !important;
    background-color: #00A656 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    width: 135px;
    padding: 8px 12px !important;

}
.pdf_edit_button{
    display: flex !important;
    height: 44px;
    padding: 6px 12px !important;
    justify-content: center;
    align-items: center;
    border-radius: 10px !important;
    background: #FFF !important;
    color: #00A656 !important;
    text-align: center !important;
    font-family: Mulish !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-right: 10px;
}
.Lightining_img{
    width: 20px;
    height: 20px;
}
.plant_image{
    width: 20px;
    height: 20px;
}
.waterdrop_img{
    width: 12px;
    height: 15px;
}
.reports_img_container{
    background-color:#E0F2FE ;
    width: 38px;
    height: 38px;
    border-radius: 50%;
}
.environment_icon_color{
    background-color:#E5F6EE  ;
}
.social_icon_color{
    background-color:#FFEDD5;
}
.governence_icon_color{
    background-color:#EDE9FE;
}
.progress_bar_container {
    width: 120px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
@media screen and (max-width:767px) {
    .home-container{
        margin: 0;
        background-color: #fff;
        height: 95vh;
    }
    .report-heading,
    .report-heading1{
        font-size: 20px;
    }
    .select-framework {
        border: 1px solid #828282 !important;
        padding: 10px 16px !important;
    }
    .reports_dropdown{
        background-color: #FFFFFF !important;
        border-radius: 10px !important;
        border: 1px solid #828282 !important;
        padding: 13px 12px 7px 20px !important;
        gap: 77px;
    }
    .edit_icon{
        width:44px !important;
        height: 44px !important;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #00A656;
        background: #FFF;
        margin-right: 20px !important;
        color: #00A656;
    }
    .pdf_upload_icon{
        width: 44px !important;
        height: 44px !important;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 10px;
        color: #FFF !important;
        background-color: #00A656;
    }
    .pdf_edit_button{
        display: none;
    }
    .input-form-group{
        padding: 0px !important;
    }
    .select-framework-container {
        position: fixed;
        top: 80px;
        left: 10px;
        z-index: 1000;
        width: 80%; 
      }
      .generate_reports_mobile_view{
        position: fixed;
        bottom: 20px;
        left: 10px;
        z-index: 1000;
        width: 156px;
        margin: 10px;
        color: #FFF;
        text-align: center;
        font-family: Mulish;
        font-size: 15px;
        font-weight: 700;
        border-radius: 4px;
        background-color:  #FFF !important;
        color: #00A656 !important;
        border: 1px solid #00A656;
      }
      .save_btn_mobile{
        position: fixed;
        bottom: 20px;
        right: 10px;
        z-index: 1000;
        width: 156px;
        margin: 10px;
        color: #FFF;
        text-align: center;
        font-family: Mulish;
        font-size: 15px;
        font-weight: 700;
        border-radius: 4px;
        background-color: #00A656 !important;
        
      }
      .save-button-mobile{
        position: fixed;
        bottom: 20px;
        left: 10px;
        z-index: 1000;
        width: 156px !important;
        margin: 10px !important;
        color: #FFF;
        text-align: center !important;
        font-family: Mulish;
        font-size: 15px !important;
        font-weight: 700 !important;
        border-radius: 4px !important;
        border: 1px solid #00A656 !important;
        background: #fff !important;
        color: #00A656 !important;
      }
      .custom-accordion{
        font-size: 20px;
      }
      .sub-category-items{
        color: var(--grey, #585858);
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 10px;
      }
        .data_container{
            height: 100vh;
        }
}
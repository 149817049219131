.preview_heading{
    height: 45px;
    color: #000;
    font-family: Mulish;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.preview_paragraph{
    height: 20px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height:20px;
}

.preview_image{
    width: 70%;
}
.arrow_image{
    width: 20px;
    height: 20px;
}
.preview_back_button{
    width: 162px;
    height: 42px;
    background-color:#E5F6EE ;
    color:#00A656;
    text-align: center;
    font-family: Mulish;
    font-size: 17px;
    font-weight: 700;
    border: none;
    margin-right: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.preview_next_button{
    background-color: #00A656;
    color:#FFF;
    margin-right: 0px;
    text-align: center;
    display: flex;
    justify-content:center ;
    align-items: center;
}
.preview_container{
    height: 100%;
    width: 100%;
}

@media screen and (max-width:767px) {
    .preview_next_button{
        color:#FFF;
        margin-right: 0px;
        width: 100%;
    }
    .frameworks_button{
        width: 100%;
    }
    .preview_next_button{
        width: 90% !important;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_image{
        width: 100%;
        height: 100%;
    }
    .preview_heading{
        font-size: 20px;
        margin-bottom: 5px;
    }
    .preview_paragraph{
        font-size: 16px;
        color: #777E90;
    }
}

@media screen and (min-width: 768px) and (max-width: 819px) {
   
    .preview_image{
        width: 75%;
        height: 100%;
    }
   
   
  }
  @media screen and (min-width: 820px) and (max-width: 1023px) {
 .preview_image{
        width: 70%;
        height: 100%;
    } 
  } 

  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .preview_image{
        width: 80%;
        height: 100%;
    } 
  } 
   
  
  @media (min-width: 992px){
    .preview_image{
        width: 76%;
        height: 100%;
    } 
  }
   
  
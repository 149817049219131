  .dropzone {
    border: 1px dashed #dfdfdf;
    text-align: center;
    border-radius: 10px;
  }
  
  .dropzone-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    height: 100% !important;
  }
  
  .dropzone-content.active {
    background-color: #eaf2fd;
  }
  
  .dropzone p {
    margin: 0;
  }
  
  .dropzone ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .dropzone li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
  }

  .show-loader {
    overflow: hidden; /* Hide scrollbars */
  }
  
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    color: #fff;
    font-size: 20px;
  }

.upload-button {
  border-radius: 4px;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 12px 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  border: none !important;
  white-space: nowrap;
  background-color: #00A656!important;
  font-weight: bold!important;
  color: #f7f7f7;
  font-family: Mulish;
}

/* .upload-button:hover, .upload-button:focus {
  background-color: #b3d3ea;
  color: #2c5777;
} */

.upload-button:focus {
  box-shadow: none;
}

/* .upload-button:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
} */

.upload-btn
{
  display: flex;
  justify-content: flex-end;
  margin-top: 20%;
  margin-right: 2%;
}
.upload-btn-popup
{
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.upload-icon{
  font-size: 28px;
  margin-left: 6px;
}
.drop-text{
  text-align: left;
  margin: 0px 18px;
}

.drop-text p {
  font-family: Mulish;
  font-weight: 600;
}

.drop-text span{
  font-size: 10px;
  color: #777E90;
  font-family: Mulish;
}

.uploaded-files{
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 20px;
  font-family: Mulish;
  font-weight: 600;
}

.uploaded-file{
  background-color: #fff;
  width: 96%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 18px;
  position: relative;
  font-family: Mulish;
  font-weight: 600;
  margin-left: 10px;
}
.files_heading{
  color: #000;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.file-icon{
  font-size: 20px;
  margin-right: 14px;
}
.file-icon-success{
  color: #00A656;
  font-size: 20px;
  margin-right: 14px;
}

.remove-button{
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #dfdfdf;
  color: #000;
}
.file-size{
  font-size: 12px;
}
.progress-container {
  width: 80%;
  height: 5px;
  background-color: #e5f6ee;
  border-radius: 3px;
  margin-left: 58px;
  position: relative;
  top: -15px;
}

.progress {
  height: 5px !important;
  border-radius: 3px;
  transition: width 0.3s ease;
  background-color: #00a656 !important;
}

.progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: #00A656;
}
.uploaded-filename {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; /* Ensure text doesn't wrap */
  display: inline-block;
  cursor: pointer; /* Show pointer cursor on hover */
  position: relative; /* Position tooltip relative to the span */
}



@media (max-width: 1024px) {
  .uploaded-filename {
    max-width: 10ch;
  }
.cross_mark_icon{
  color: #000 !important;
}
}
.submit-button{
  width: 20%;
}
.submit-button-popup{
  width: 30%;
  margin-bottom: 10px;
}
.upload1{
  width: 100%;
}
.manual-start-button{
  background-color: #00A656;
  color: #fff;
  border: none;
  padding: 8px 20px;
  width: 70%;
  font-family: Mulish;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
}

.start-manually{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  
}
.manual-text{
  font-size: 12px;
  margin-top: 5px;
  color: #777E90;
  text-align: center;
  width: 60%;
  font-weight: 400;
}

@media screen and (max-width:767px) {
  .file_image{
    width: 80%;
  }
  .dropzone_text.desktop-only {
    display: none;
  }
  .dropzone_text.mobile-only {
    display: block;
    margin-bottom: 6px;

  }
  .fileupload_card{
    display: flex;
    flex-direction: column;
  }
  .dropzone-content{
    background-color:#E5F6EE ;
  }
  .upload-icon{
    margin-bottom: 10px;
  }
  .pdf_text{
    margin-bottom: 6px;
    text-align: center;
  }
  .upload-button.desktop-only{
    display: none;
  }
  .upload-button.mobile-only{
    display: block;
    width: 90% !important;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .upload-button.mobile-top {
    display: block;
    width: 100% !important;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    color: #00A656;
    background-color: #fff !important;
    border: 1px solid #00A656 !important;
    border-radius: 4px;
  }
  .uploaded-file{
    margin-left: 0;
  }
  .mobile-submit-button {
    width: 90% !important;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .uploaded-file{
    border:1px solid #DFDFDF ;
  }
}

@media screen and (min-width: 768px) {
  .dropzone_text.desktop-only {
    display: block;
  }
  .dropzone_text.mobile-only {
    display: none;
  }
  .upload-button.mobile-only{
    display: none;
  }
  .upload-button.mobile-top {
    display: none;
  } 
}
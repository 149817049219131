.framework_heading{

    height: 45px;
    color: #000;
    font-family: Mulish;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.framework_paragraph{
    color:  #777E90;
    height: 20px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height:20px;
}

.frameworks_card_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;   
}
.environment_images{
    width: 55%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}
.frameworks_checkbox{
    margin: 10px;
}
.checkboxes_container{
    width: 90%;
    margin-top: 28px;
 
}

.framework_checkbox_label{
    color: #000;
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.framework_check_box{
    accent-color: #00A656;
    background-color: #E5F6EE;
    color: #000;
    margin-right: 12px;
}
.framework_check_box[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background-color:#E5F6EE ;
    border-radius: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.framework_check_box[type="checkbox"]:after{
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    font-weight: 900;
    font-size: 20px;
    color:#FFF;
    display: none;
}
input[type="checkbox"]:hover{
    background-color:#84b384;
}
input[type="checkbox"]:checked{
    background-color: #00A656;
}
input[type="checkbox"]:checked:after{
    display: block;
}
.framework_back_button{
    width: 162px;
    height: 42px;
    background-color:#E5F6EE ;
    color:#00A656;
    text-align: center;
    font-family: Mulish;
    font-size: 17px;
    font-weight: 700;
    border: none;
    margin-right: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.framework_next_button{
    background-color: #00A656;
    color:#FFF;
    margin-right: 0px;
    text-align: center;
    display: flex;
    justify-content:center ;
    align-items: center;
}
.framework-nav-button{
    width: 100%;
    display: flex;
    justify-content: end;
}

@media screen and (max-width: 767px) {
    .frameworks_card_container{
        height: 100vh;
    }
    .framework_next_button{
        /* display: flex;
        justify-content: center;
        width: 100%; */
        width: 90% !important;
        position: absolute;
        bottom: 18px;
        left: 50%;
        transform: translateX(-50%);
    }
    .framework-nav-button{
        display: flex;
        justify-content: center;
    }
    .frameworks_checkbox {
        width: 45%;
        margin: 5px;
    }
    .framework_checkbox_label{
        margin: 0px;
    }
    .framework_heading{
        font-size:20px ;
    }
    .framework_paragraph{
        font-size: 16px;
    }
    .checkboxes_container{
        margin-left: 50px;
        margin-top: 60px;
    }
    .checkbox_card{
        height: 100vh;
    }
    .framework_btn{
        display: none;
    }
    .framework_container{
        overflow:hidden;
    }
}

@media screen and (min-width: 768px) and (max-width: 819px) {
    .checkbox_card{
        margin-bottom: auto;
    }
  }
  @media screen and (min-width: 820px) and (max-width: 1023px) {
   
      .checkbox_card{
        margin-bottom: auto;
    }  
  }

  @media (min-width: 992px){
    .checkbox_card{
        margin-bottom: auto;
    }
   
  }
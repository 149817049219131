.topbar-container{
    display: flex;
    align-items: center;
    padding: 24px 0px 15px 0px;
}


.company-select{
    padding: 16px 12px !important;
    color: #00A656 !important;
    background-color: #ededed !important;
    outline: none !important;
    border-radius: 10px !important;
    font-family: DM Sans;
    border: none !important;
}

.company-select:focus{
    border-color: transparent !important;
    box-shadow: none !important;
}

.bell-icon{
    background-color: #fff;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    font-size: 22px;
}

.line{
    background-color: #D0D6DE;
    width: 2px;
    height: 56px;
    margin: 0;
    padding: 0;
    margin-left: 22px;
    margin-right: 16px;
}

.profile-name{
    height: 40px;
    width: 40px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 600;
    font-family: DM Sans;
    border-radius: 50%;
    margin: 0px 10px;
}

.company-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2300A656%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
}

.header-right{
    visibility: hidden;
}

.company-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px; 
    color:#131313;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 500;
    
  }
  
  .company-item {
    /* background-color: #ECECEC;  */
    /* background-color: #F0ECEC; */
    padding: 10px;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    border-radius: 4px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 767px) {
    .company-container{
        display: none;
    }
    
  }
.close_upload_popup{
    font-size: 30px;
    cursor: pointer;
    margin-top: 0;
    border: none;
    background-color: transparent;
    margin-right: 20px;
    margin-bottom: 0px;
}
.overlay-styles{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    z-index: 1000;
}
.file_upload_card{
    background-color:  #F4F4F4;
    margin-left: 40px;
}
.upload-document-modal{
    width: 44%;
    position: absolute;
    top: 8%;
    left: 33%;
    border-radius: 10px; 
    background-color: #fff;
    min-height: 80%;
    max-height: 90%;
    overflow-y: auto;
    background-color:  #F4F4F4;

    /* padding: 28px; */
}
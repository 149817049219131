.bg_container{
    display: flex;
    height: 100vh;
    flex-shrink: 0;
    opacity: 0.8;
    background-image: url('https://res.cloudinary.com/dy5wntnib/image/upload/v1698928739/5333978_1_1_qe0hum.png') ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.left_container{
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(2.5px);
    color: #00000005;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 38px;
    padding-right: 100px;
}

.signup_heading{
    color:  #000000;
    font-family: Rajdhani;
    font-size: 56px;
    font-weight: 700;
    line-height: normal;
}

.signup_heading_highlight{
    color:  #00A656;
    font-family: Rajdhani;
    font-size: 48px;
    font-weight: 700;

}

.signup_paragraph{
    flex-shrink: 0;
    color: var(--black, #000);
    font-family: Mulish;
    font-size: 20px;
    font-weight: 500;
}

.right_container{
    height: 100vh;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-form{
    width: 100%;
}

.form_container,
.form_container_fpw,
.form_container_email_sent,
.form_container_reset_pw,
.form_container_pw_success
{
    display: inline-flex;
    padding: 32px 32px 16px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(4.923076629638672px);
    width: 434px;
}

.input_box_logo{
    display: flex;
    justify-content: center;
    gap: 7.956px;
}
.vector_image{
    width: 48px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 7px;
    gap: 7.956px;
}
.esgpilot_heading{
    color: #00A656;
    font-family: Syncopate;
    font-size: 36.476px;
    font-weight: 700;
    padding-bottom:16px;
}
.pilot{
    color: #00A656;
    font-family: Syncopate;
    font-size: 36.476px;
    font-weight: 400;
}
input::-ms-reveal,
input::-ms-clear {
    display: none;
}
.input_box{
    width: 100%;
    height: 54px;
    flex-shrink: 0;
    border-radius: 9.846px;
    border: 0.985px solid #E5F6EE;
    background: #FFF;
    color: #777E90;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px; /* 137.143% */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    padding:15.75px 0px 18.25px 22.06px
}
.password_img_container{
    display: flex;
    position: relative;
}
.eye_image{
    width: 24px;
    height: 24px;
    position: relative;
    top: 15px;
    left: -40px;
    cursor: pointer;
}
.signup_button{
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 14.769px;
    background:  #00A656;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin-top: 16px;
    color: #FFFFFF;
    text-align: center;
    font-family: Karla;
    font-size: 18px;
    font-weight: 700;
    line-height: 28.16px;
}
.signup_text{
    color: #585858;
    text-align: center;
    font-family: Karla;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.92px;
    margin-top: 6px;
}
.login_link_sgu{
    color: var(--grey, #585858);
    font-family: Karla;
    font-size: 13.785px;
    font-weight: 700;
    line-height: 17.92px;
    text-decoration-line: underline;
    cursor: pointer;
}


.password-input-container {
    position: relative;
  }
  
  .password-checklist-card {
    position: absolute;
    top: 63px;
    left: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .custom-checklist-styles{
    font-family: Mulish;
    font-size: 14px;
  }

  .custom-checklist-styles li {
    margin-bottom: 5px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 700 !important;
  }

  .password-checklist-card::before{
    content: '';
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid lightgray;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .Forget_password {
      font-family: Mulish;
      font-size: 36px;
      font-weight: 500;
      color: #000;
      margin-bottom: 5px !important;
      margin-left: 10px;

  }

  .forget_password_content {
      font-family: Mulish;
      font-size: 16px;
      font-weight: 400;
      color: #777E90;
     margin-left: 10px;

  }

  .email_sent{
    text-align: left !important;
  }

  .back_to_login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 14.769px;
    background:  #00A656;
    border: none;
    gap: 6px;
    margin-top: 16px;
    color: #FFFFFF;
    text-align: center;
    font-family: Karla;
    font-size: 18px;
    font-weight: 500;
    /* Add other styles as needed */
  }
  
  .back_arrow {
    margin-right: 1px; 
    color: #FFFFFF!important;
    font-size: larger;
    height: 20px;
  }

  .new_password {
      font-family: Mulish;
      font-size: 32px;
      font-weight: 450;
      color: #000000;
      white-space: nowrap;
      margin-left: 5px;
  }

@media screen and (min-width: 768px) and (max-width: 820px) {
    .signup_heading {
        font-size: 44px;
    }

    .signup_heading_highlight {
        font-size: 27px;
    }

    .signup_paragraph {
        font-size: 14px;
    }

    .form_container {
        width: 360px;
        height: 370px
    }

    .form_container_fpw {
        width: 360px;
        height: 310px;
        margin-bottom: 45px;

    }

    .form_container_email_sent {
        width: 340px;
        height: 210px;
        margin-bottom: 140px;
    }

    .form_container_pw_success {
        width: 345px;
        height: 280px;
        margin-bottom: 100px;
    }

    .Forget_password {
        font-size: 30px;
    }

    .form_container_reset_pw {
        width: 340px;
        margin-bottom: 38px;
    }

    .new_password {
        font-size: 28px;
    }

    .left_container {
        background: none;
    }



}

@media screen and (min-width: 821px) and (max-width: 1024px) {
    .signup_heading {
        font-size: 46px;
    }

    .signup_heading_highlight {
        font-size: 30px;
    }

    .signup_paragraph {
        font-size: 14px;
    }

    .form_container {
        width: 360px;
        height: 370px
    }

    .form_container_fpw {
        width: 360px;
        height: 310px;
        margin-bottom: 45px;

    }

    .form_container_email_sent {
        width: 360px;
        height: 220px;
        margin-bottom: 140px;
    }

    .form_container_pw_success {
        width: 360px;
        height: 280px;
        margin-bottom: 100px;
    }

    .Forget_password {
        font-size: 30px;
    }

    .form_container_reset_pw {
        width: 340px;
        margin-bottom: 38px;
    }

    .new_password {
        font-size: 28px;
    }

    .left_container {
        background: none;
    }



}

@media screen and (max-width:767px) {
    .signup_button{
        border-radius: 4px;
        padding: 8px 92px;
        gap: 10px;
        height: 42px;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 600;
    }
    .Forget_password{
        color:#000;
        font-family: Mulish;
        font-size: 20px;
        font-weight: 500;
    }
    .forget_password_content{
        font-size: 12px;
    }
    .back_to_login{
        border-radius: 4px;
        height: 42px;
        font-size: 16px;
    }
    .back_arrow{
        width: 20px;
        height: 20px;
    }
    .new_password{
        font-family: Mulish;
         font-size: 20px;
         font-weight: 500;
    }
    .left_container,
    .left_container_login{
        display: none;
    }
    .form_container{
        width: auto;
        height: auto;
    }
}

 
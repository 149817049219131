.navbar-container {
  background-color: #fff;
  box-shadow: 4px 0px 11px 0px rgba(0, 0, 0, 0.05);
  width: 88px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
}
/* @media (max-width: 1024px) {
    .logout-container{
        margin-bottom: 150px;
    }
} */
.mobile-navbar-container {
  height: 100%;
}
.company-logo {
  width: 97%;
  margin-bottom: 44px;
}

.nav-list {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.home-icon {
  font-size: 24px;
}

.reports-icon {
  font-size: 24px;
}
.bell-icon {
  width: 24px;
  height: 24px;
  background-color: #00a656;
}
.company_container {
  display: flex;
  width: 40px;
  height: 24px;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: #fff;
}
.nav-item {
  padding: 13px;
  border-radius: 20px;
  margin-bottom: 24px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-container {
  padding: 16px;
  cursor: pointer;
}

.logout-icon {
  font-size: 24px;
  color: #00a656;
}

.bg-green {
  background-color: #00a656;
  color: #fff;
}

.bg-white {
  color: black;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #00a656;
}

.navbar-left .icon,
.navbar-right .icon {
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.navbar-right {
  display: flex;
  gap: 15px;
  align-items: center;
}

.company-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Monitor Navbar Styles */
.monitor-navbar {
  display: none; /* Hidden by default, shown on larger screens */
}

.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: -250px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.3s;
  padding: 40px 24px 32px 24px;
  z-index: 1000;
}

.sidebar.open {
  left: 0;
}

.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #464255;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #00a656;
}

.navbar-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  transition: 0.3s;
  z-index: 999;
}

.navbar-overlay.show {
  display: block;
}

.nav-list {
  list-style: none;
  padding: 0;
}

.nav-item {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
}

.nav-item-text {
  margin-left: 10px; /* Add margin between icon and text */
}

.bg-green {
  background-color: #00a656;
  color: white;
}

.bg-white {
  background-color: white;
  color: #333;
}

.logout-container {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Mobile Navbar Styles */
.mobile-navbar {
  display: none; /* Hidden by default, shown on smaller screens */
}
.back-icon{
  color: #fff;
  width: 20px;
  height: 24px;
  font-weight: bold !important;
}

/* Media Queries */
@media (max-width: 767px) {
  .monitor-navbar {
    display: none;
  }
  .mobile-navbar {
    display: flex;
  }
  .nav-item-text {
    display: inline;
    color: #0b132a;
  }
  .nav-item {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .active-tab {
    color: #00a656;
  }
  .navbar-logo-container {
    display: flex;
    padding: 14px 14.571px 14px 16px;
    justify-content: center;
    align-items: center;
    /* gap: 8.429px; */
    border-radius: 16px;
    background-color: #e5f6ee;
    width: 216px;
    height: 56px;
  }
  .esgPilot-logo {
    width: 100%;
  }
  .my_account_heading {
    color: #0b132a;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    padding: 8px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 0;
  }
  .profile_container {
    display: flex;
    padding: 10px 5px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.8px solid #dfdfdf;
  }
  .profile_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .profile_pic {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .user_name {
    color: #464255;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  .bell-icon {
    color: #fff;
  }
  .logout-icon {
    color: #0b132a;
  }
}
@media (min-width: 768px) {
  .monitor-navbar {
    display: flex;
  }
  .mobile-navbar {
    display: none;
  }
}
.category_name{
  color: #FFF;
  text-align: right;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
}

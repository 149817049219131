.bg_container_image{
    display: flex;
     height: 100%;
    min-height: 100vh;
    flex-shrink: 0;
    opacity: 0.8;
    background-image: url('https://res.cloudinary.com/dy5wntnib/image/upload/v1698928739/5333978_1_1_qe0hum.png') ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.left_container_login{
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.02);
    backdrop-filter: blur(2.5px);
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right_container_login{
    height: 100vh;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.login-form{
    width: 100%;
}

.form_container_login{
    display: inline-flex;
    padding: 32px 32px 16px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(4.923076629638672px);
    width: 434px;
}

.forget_password_text{
    width: 188px;
    color: #00A656;
    text-align: right;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.checkbox_container{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
}

.checkbox_style{
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 0.985px solid #DADADA;
    background: #FFF;
    accent-color: #00A656;
    margin-right: 13px;
}

input[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-color:#E5F6EE ;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
input[type="checkbox"]:after{
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    font-weight: 900;
    font-size: 14px;
    color:#FFF;
    display: none;
}
input[type="checkbox"]:hover{
    background-color:#84b384;
}
input[type="checkbox"]:checked{
    background-color: #00A656;
}
input[type="checkbox"]:checked:after{
    display: block;
}

.remember_me_text{
    color:  #777E90;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    
}
.login_heading{
    color: #000;
    font-family: Rajdhani;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.login_heading_highlight{
    color:  #00A656;
    font-family: Rajdhani;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.login_paragraph{
    /* width: 537px; */
    height: 252px;
    color: #000;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 139%;
}

.login_heading_fpw{
   
    color: #000;
    font-family: Rajdhani;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.login_heading_highlight_fpw{
    color:  #00A656;
    font-family: Rajdhani;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.login_paragraph_fpw{
 
    height: 252px;
    color: #000;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 139%;
}

.login_button{
    width: 100%;
    height: 58px;
    flex-shrink: 0;
    border-radius: 14.769px;
    background:  #00A656;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin-top: 16px;
    color: #FFFFFF;
    text-align: center;
    font-family: Karla;
    font-size: 21.662px;
    font-weight: 700;
    line-height: 28.16px;
}

@media screen and (min-width: 768px) and (max-width: 820px) {
    .login_heading {
        font-size: 39px;
    }

    .login_heading_highlight {
        font-size: 50px;

    }

    .login_paragraph {
        font-size: 14px;
        font-weight: 700;

    }

    .form_container_login {
        width: 340px;
        height: 400px
    }

    .login_heading_fpw {
        font-size: 28px;

    }

    .login_heading_highlight_fpw {

        font-size: 36px;

    }

    .login_paragraph_fpw {
        font-size: 13px;
        font-weight: 700;

    }

    .left_container_login {
        background: none;
    }

    .forget_password_text {
        font-size: 13px;
    }

    .remember_me_text {
        font-size: 13px;
    }

}

@media screen and (min-width: 821px) and (max-width: 1024px) {
    .login_heading {
        font-size: 39px;
    }

    .login_heading_highlight {
        font-size: 50px;

    }

    .login_paragraph {
        font-size: 14px;
        font-weight: 700;

    }

    .form_container_login {
        width: 340px;
        height: 400px
    }

    .login_heading_fpw {
        font-size: 34px;

    }

    .login_heading_highlight_fpw {

        font-size: 38px;

    }

    .login_paragraph_fpw {
        font-size: 14px;
        font-weight: 700;

    }

    .left_container_login {
        background: none;
    }

    .forget_password_text {
        font-size: 13px;
    }

    .remember_me_text {
        font-size: 13px;
    }

}

@media screen and (max-width:767px) {
    .forget_password_text{
        font-size: 12px;
    }
    .remember_me_text{
        font-size: 12px;
    }
    .left_container_login{
        display: none;
    }
    .form_container_login,
    .form_container, 
    .form_container_fpw, 
    .form_container_email_sent, 
    .form_container_reset_pw, 
    .form_container_pw_success{
        width: auto;
        height: auto;
    }
    .signup_text{
        font-size:  13.785px;
        color: #585858;
    }
    .login_button{
        height: 45px;
        border-radius:4px;
    }
}






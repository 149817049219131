.categories .list-group-item {
    border: none;
    padding: 10px 10px 0px 10px;
    font-size: 16px;
    color: #585858;
    font-family: Mulish;
  }
  
  .categories .list-group-item .list-group {
    border: none;
  }
  
  .categories .list-group-item .list-group-item {
    border: none;
   
  }
  
  .categories .list-group-item .list-group-item button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: #000;
  }
  
  .categories .list-group-item .list-group-item button:focus,
  .categories .list-group-item .list-group-item button:hover {
    background-color: transparent;
    color: #000;
    text-decoration: underline;
  }

  .esg-category 
  {
    color:#00A656;
    font-weight: bold;
    font-family: Mulish;
    font-weight: 600;
  }

  body{
    background-color: #f7f7f7!important;
  }
  .input-form-group
  {
    margin-bottom: 16px;
  }

  .default-btn
  {
    border: 1px solid #00A656!important;
    color:#00A656!important;
    font-weight:bold!important;
    float:right;
    width:200px;
    margin-top: 30px;
  }
  .success-btn
  {
    font-weight:bold!important;
    float:right;
    width:200px;
    margin-left: 10px;
    margin-top: 30px;
    background-color: #00A656!important;
    color: #fff !important;
    /* border: 1px solid #00A656!important; */
  }
  .arrow-down
  {
    float:right;
  }
  .form-section
  {
    border-radius: 10px;
    background-color: #fff;
    padding: 30px 0px;
  }
  .line-section{
    width: 62px;
    margin-top: -16px;
    margin-left: 75px;
    background: #f7f7f7;
    font-size: 19px;
    color: #333;
    font-weight: 600;
  }
  .input-form-group {
    margin-bottom: 16px;
    padding-left: 30px;
    padding-right: 30px;
}
.main-container
{
  margin-top:24px;
}

.categories{
  background-color: #fff;
  padding: 6px;
  border-radius: 10px;
}

.custom-accordion{
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}


.accordion__button {
  background-color: #fff !important;
  align-items: center;
  padding: 8px !important;
  color: black !important;
  font-family: Mulish;
  font-weight: 600;
}

.sub-category-items{
  list-style: none;
  font-family: Mulish;
  color: #585858;
}

.accordion__button::before {
  float: right;
  background-color: #fff;
  margin-top: 9px;
}

.accordion__button.active::before {
  margin-top: 7px;
}

.active{
  color: #00A656 !important;
}

.data-point{
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

.answer-text{
  border-radius: 10px !important;
  color: #585858 !important;
  font-family: Mulish;
}

.save-button{
  margin: 50px 0px 20px 0px;
  font-family: Mulish !important;
  border-radius: 10px !important;
}

.custom-circular-bar{
  width: 100% !important;
}

.progress-bar{
  margin-right: 18px;
}


@media (min-width: 767px) {
  
}
